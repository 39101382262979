<template>
    <div class="box-card" style="text-align: left;">
        <el-form :model="addForm" ref="addFormRefs" :rules="addFormRules" label-width="120px" class="addForm">
            <el-form-item label="场景名称：" prop="scene_info.scene_name">
                <el-input placeholder="请输入场景名称" autocomplete="off" maxlength="15" :value="addForm.scene_info.scene_name"
                    @input="(e) => (addForm.scene_info.scene_name = validSpace(e))"></el-input>
            </el-form-item>
            <el-form-item label="场景ICON：">
                <el-image class="img_box" fit="contain"
                    @click="isIconDialog = true; sceneIcon = addForm.scene_info.scene_icon"
                    :src="require(`@/assets/images/scene/scene${addForm.scene_info.scene_icon}.png`)"></el-image>
                <!-- <div class="add_icon_item" @click="isIconDialog = true" v-else>
            <i class="iconfont lebo-tubiaoadd"></i>
          </div> -->
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item label="平台域名：" prop="scene_info.domain_address">
                <el-input placeholder="请输入域名" class="domainSuffix_box" v-model="addForm.scene_info.domain_address"
                    @input="(e) => (addForm.scene_info.domain_address = validSpace(e))">
                    <template slot="append">{{ domainSuffix }}</template>
                </el-input>
            </el-form-item>
            <el-form-item label="平台名称：" prop="scene_info.platform_name">
                <el-input placeholder="请输入平台名称" autocomplete="off" maxlength="30" :value="addForm.scene_info.platform_name"
                    @input="(e) => (addForm.scene_info.platform_name = divideSymbol(e))"></el-input>
            </el-form-item>
            <el-form-item label="LOGO：">
                <div class="logo_upload_box">
                    <el-image fit="contain" :src="addForm.scene_info.logo_url"
                        :preview-src-list="[addForm.scene_info.logo_url]" v-if="addForm.scene_info.logo_url" :z-index="9000"></el-image>
                    <div class="handelButton">
                        <lbButton size="small" type="warning" class="deleteLogo" @click="deleteLogo"
                            v-if="addForm.scene_info.logo_url">删除</lbButton>
                        <el-upload class="logo-upload" action="''" :http-request="requestUploadImg" :show-file-list="false"
                            accept="image/gif,image/jpeg,image/jpg,image/png" :before-upload="beforeAvatarUpload2">
                            <lbButton size="small" :type="addForm.scene_info.logo_url ? 'warning' : 'default'">{{
                                addForm.scene_info.logo_url ? "重新上传" :
                                "上传"
                            }}</lbButton>
                        </el-upload>
                        <span class="logoinfo el-icon-warning-outline">说明:仅支持jpg,gif,jpeg,png格式的图片，大小&lt;=1MB</span>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="主题：">
                <ul class="theme_box">
                    <li :class="['img_box', addForm.scene_info.theme == item ? 'is_check' : '']" v-for="item in themeList"
                        :key="item" @click="addForm.scene_info.theme = item">
                        <el-image fit="contain"
                            :src="require(`@/assets/images/group/theme${item}.png`)"></el-image>
                        <i class="iconfont lebo-chenggong1" v-if="addForm.scene_info.theme == item"></i>
                    </li>
                </ul>
                <!-- <div class="theme_box">
            <div class="img_box">
              <el-image fit="contain" :src="require(`../../assets/images/group/theme${addForm.scene_info.theme}.png`)" :preview-src-list="[require(`../../assets/images/group/theme${addForm.scene_info.theme}.png`)]"></el-image>
            </div>
            <el-radio-group v-model="addForm.scene_info.theme">
              <el-radio :label="1">样式1</el-radio>
              <el-radio :label="2">样式2</el-radio>
              <el-radio :label="3">样式3</el-radio>
              <el-radio :label="4">样式4</el-radio>
            </el-radio-group>
          </div> -->
            </el-form-item>
            <div style="display:flex;">
                <el-form-item label="平台菜单包：" prop="menus_custom">
                    <lbButton size="small"
                        :type="addForm.menus_custom && addForm.menus_custom.children && addForm.menus_custom.children.length > 0 ? 'warning' : 'default'"
                        @click="isConfigMenusCustom = true;">{{
                            addForm.menus_custom && addForm.menus_custom.children &&
                            addForm.menus_custom.children.length > 0 ? '修改配置' : '配置'
                        }}</lbButton>
                </el-form-item>
                <el-form-item label="大屏：">
                    <lbButton size="small"
                        :type="addForm.screen_custom && addForm.screen_custom.length > 0 ? 'warning' : 'default'"
                        @click="isConfigScreenCustom = true">{{
                            addForm.screen_custom && addForm.screen_custom.length > 0 ? '修改配置' :
                            '配置'
                        }}</lbButton>
                </el-form-item>
                <!-- <el-form-item label="app功能包：" prop="plate_custom">
            <el-button size="small" type="warning" @click="isConfigPlateCustom = true">{{ addForm.plate_custom ?
                              '已配置' : '配置'
                      }}</el-button>
          </el-form-item> -->
            </div>
            <el-form-item label="手机端：">
                <el-checkbox-group v-model="addForm.scene_info.phone_client">
                    <el-checkbox v-for="item in getOptions('GroupPhoneClient')" :key="item.value" :label="item.value">{{
                        item.name
                    }}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="快捷入口：">
                <el-checkbox-group v-model="addForm.scene_info.shortcut">
                    <el-checkbox v-for="item in getOptions('GroupShortCut')" :key="item.value" :label="item.value">{{
                        item.name
                    }}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <lbButton type="default" fill icon="confirm" class="checkButton" @click="onSubmit()" v-preventReClick>保 存</lbButton>
            <!--  -->
        </el-form>

        <lebo-dialog  append-to-body title="场景ICON" :isShow="isIconDialog" width="50%" @close="isIconDialog=false" footerSlot>
            <ul class="theme_dialog_box">
                <li :class="['img_box', sceneIcon == item ? 'is_check' : '']" v-for="item in sceneIconList" :key="item"
                    @click="clickIcon(item)">
                    <el-image fit="contain" :src="require(`@/assets/images/scene/scene${item}.png`)"></el-image>
                    <i class="iconfont lebo-chenggong1" v-if="sceneIcon == item"></i>
                </li>
            </ul>
            <!-- <div slot="footer" class="dialog-footer">
          <el-button @click="isIconDialog = false">取 消</el-button>
          <el-button type="primary" @click="addForm.scene_info.scene_icon =  sceneIcon;isIconDialog = false">保 存</el-button>
        </div> -->
        </lebo-dialog>
        <configMenusScene :menusCustom="addForm.menus_custom" :addNewId="id" @save="saveConfigMenusCustom"
            @close="isConfigMenusCustom = false" v-if="isConfigMenusCustom"></configMenusScene>
        <configScreenScene :screenCustom="addForm.screen_custom" @save="saveConfigBigScreenCustom"
            @close="isConfigScreenCustom = false" v-if="isConfigScreenCustom" @removeAll="removeAllScreenCustom"></configScreenScene>
    </div>
</template>
<script>
import systemapi from '@/api/systemapi'
import { mapGetters, mapState, mapMutations } from 'vuex'
import configMenusScene from '@/components/configMenusCustom/index.vue'
import configScreenScene from '@/components/configScreenCustom/index.vue'
export default {
  props: ['id'],
  components: {
    configMenusScene,
    configScreenScene
  },
  data () {
    var requiredVla = (rule, value, callback) => {
      console.log('校验菜单', value)
      if (value && value.children && value.children.length > 0) {
        callback()
      } else {
        callback(new Error('请配置菜单'))
      }
    }
    return {
      // sceneIconList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40'], // 对应场景icon图片名字
      sceneIconList: ['1-1', '1-2', '1-3', '1-4', '1-5', '1-6', '1-7', '2-1', '2-2', '2-3', '2-4', '3-1', '3-2', '3-3', '3-4', '4-1', '4-2', '4-3', '4-4', '4-5', '5-1', '5-2', '5-3', '5-4', '5-5', '5-6', '5-7', '5-8', '5-9', '5-10', '5-11', '5-12', '5-13', '5-14', '5-15', '5-16', '5-17', '5-18', '5-19', '5-20'], // 对应场景icon图片名字
      themeList: [1, 2, 3, 4],
      addForm: {
        scene_info: {
          scene_name: '', // 场景名称
          scene_icon: '5-1', // 场景图标
          domain_address: '', // 域名地址
          platform_name: '', // 平台名称
          logo_url: '', // logo地址
          login_page: 1, // 登录页
          theme: 1,
          is_scene: false, // 是否配置场景
          phone_client: [], // 手机端 //对应枚举GroupPhoneClient
          shortcut: [] // 快捷入口 //对应枚举 GroupShortCut
        }, // 集团信息
        is_update_menus_custom: false, // 是否修改菜单，控制编辑时是否修改菜单自定义数据
        menus_custom: {}, // 集团菜单自定义信息
        screen_custom: [], // 集团大屏自定义信息(只需要已勾选的数据，is_open全为false)
        plate_custom: null // 集团APP板块自定义信息
      },
      addFormRules: {
        scene_info: {
          scene_name: [{ required: true, message: '请输入场景名称', trigger: 'blur' }],
          platform_name: [{ required: true, message: '请输入平台名称', trigger: 'blur' }],
          domain_address: [{ required: true, message: '请输入域名地址', trigger: 'blur' }]
        },
        menus_custom: [{ required: true, validator: requiredVla, trigger: 'blur' }]
        // plate_custom: [{ required: true, message: "请配置app功能包", trigger: "blur" }],

      },
      // 是否展示配置平台菜单包对话框
      isConfigMenusCustom: false,
      isConfigScreenCustom: false,
      isConfigPlateCustom: false,
      domainSuffix: '',
      // 默认域名地址
      domainAddress: '',
      isIconDialog: false,
      sceneIcon: ''
    }
  },
  created () {
    this.fnGetCustomDomainSuffix()
    if (this.id) {
      this.fngetSceneDetail()
    }
  },
  mounted () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {},
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 获取集团详情
    async fngetSceneDetail () {
      console.log('fngetSceneDetail----')
      const res = await systemapi.getSceneDetail({ scene_id: this.id })
      console.log('获取集团详情----', res)
      if (res && res.Code === 200) {
        this.addForm = JSON.parse(JSON.stringify(res.Data))
        if (res.Data.scene_info.scene_icon.indexOf('-') === -1) {
          this.addForm.scene_info.scene_icon = '5-1'
        }
        this.addForm.scene_info.domain_address = this.addForm.scene_info.domain_address.replace('.' + this.domainAddress, '')
        this.addForm.is_update_menus_custom = false
      }
    },
    // 获取自定义域名后缀
    async fnGetCustomDomainSuffix () {
      const res = await systemapi.getCustomDomainSuffix()
      if (res && res.Code === 200) {
        this.domainSuffix = '.' + res.Data
        this.domainAddress = res.Data
      }
    },
    // 返回
    defaultForm () {
      this.setQueryStatus(2)
      this.$emit('closeDialog', false)
    },
    // 上传图片
    requestUploadImg (item) {
      const indexpoint = item.file.name.lastIndexOf('.')
      const imageName = item.file.name.substr(indexpoint)
      // console.log("-----...-----" + imageName);
      // 预览图片
      const src = window.URL.createObjectURL(item.file)
      // console.log("==-=-=src-=-=", src);
      // 将图片文件转化成base64格式图片
      var reader = new FileReader()
      reader.onload = async (e) => {
        // e.target.result  就是从本地读取的图片的base64格式,将它上传给服务器即可
        // 使用axios的post方法上传即可
        var base64image = e.target.result
        // console.log("==-=-base64image=-=-", base64image);
        const res = await systemapi.updataIcon({ name: item.file.name, strbase64: [base64image] })
        var url = ''
        if (res.data && res.data.length > 0) {
          url = res.data[0].FilePath
        } else {
          this.$msg.warning('你的图片格式有误请重新选择!')
        }
        this.addForm.scene_info.logo_url = url
      }
      reader.readAsDataURL(item.file)
    },
    // 保存自定义菜单
    saveConfigMenusCustom (item) {
      console.log(item)
      if (this.id && JSON.stringify(this.addForm.menus_custom) !== JSON.stringify(item)) {
        this.addForm.is_update_menus_custom = true
      } else {
        this.addForm.is_update_menus_custom = false
      }
      this.addForm.menus_custom = item
      // this.addForm.menus_custom = item;
    },
    // 保存自定义大屏
    saveConfigBigScreenCustom (list) {
      console.log('saveConfigBigScreenCustom---', list)
      this.addForm.screen_custom = list
    },
    // 保存自定义app包
    savePlateCustom (item) {
      this.addForm.plate_custom = item
    },
    // 选择场景icon
    clickIcon (item) {
      console.log('选择场景图标', item)
      this.sceneIcon = item
      this.addForm.scene_info.scene_icon = item
      this.isIconDialog = false
    },
    // 保存提交
    onSubmit () {
      this.$refs.addFormRefs.validate((valid) => {
        if (valid) {
          var obj = JSON.parse(JSON.stringify(this.addForm))
          if (obj.screen_custom.length > 0) {
            obj.screen_custom.forEach(element => {
              element.children.forEach(item => {
                delete item.graph_type
              })
            })
          }
          console.log(this.addForm)
          obj.scene_info.domain_address = this.addForm.scene_info.domain_address + this.domainSuffix
          if (this.id) {
            obj._id = this.id
            this.fnupdataGroup(obj)
          } else {
            this.fnaddGroup(obj)
          }
        }
      })
    },
    async fnaddGroup (obj) {
      console.log(JSON.stringify(obj))
      const res = await systemapi.addSceneList(obj)
      console.log(res)
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    },
    async fnupdataGroup (obj) {
      const res = await systemapi.updateSceneList(obj)
      if (res && res.Code === 200) {
        this.defaultForm()
      }
    },
    // 删除已上传
    deleteLogo () {
      this.addForm.scene_info.logo_url = ''
    },
    // 删除所有已配置大屏
    removeAllScreenCustom () {
      this.addForm.screen_custom = []
    }
  }
}
</script>
<style scoped lang="less">
.box-card {
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 30px;
    max-height: 650px;
    overflow-y: auto;

    .checkButton {
        position: absolute;
        right: 10px;
        // bottom: 0;
    }

    .checkButton2 {
        position: absolute;
        right: 110px;
        // bottom: 0;

    }
}

.addForm {
    .el-form-item {
        margin-right: 180px;
    }

    /deep/ .img_box {
        width: 100px;
        height: 100px;
        margin-right: 10px;
        border: 1px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        cursor: pointer;
        position: relative;

        .el-image {
            width: 100%;
            height: 100%;
        }

        .iconfont {
            position: absolute;
            bottom: 5px;
            right: 5px;
            font-size: 24px;
            color: #01a6fb;
        }
    }

    /deep/ .el-select,
    /deep/.el-input {
        width: 400px;
        margin-right: 10px;
    }

    /deep/ .logo_upload_box {
        //   display: flex;
        //   align-items: center;
        .handelButton {
            display: flex;
            align-items: center;

            .logoinfo {
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #CCCCCC;

                &::before {
                    margin-right: 4px;
                }
            }
        }

        .el-image {
            height: 100px;
            margin-right: 10px;

            img {
                width: auto;
                background-color: #eee;
            }
        }
    }

    /deep/ .theme_box {
        display: flex;
        align-items: center;

        .img_box {
            width: 198px;
            height: 108px;
            margin-right: 10px;
            border: 1px solid #ccc;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            cursor: pointer;
            position: relative;

            .el-image {
                width: 100%;
                height: 100%;
            }

            .iconfont {
                position: absolute;
                bottom: 5px;
                right: 5px;
                font-size: 24px;
                color: #01a6fb;
            }
        }

        .is_check {
            border: 1px solid #01a6fb;
        }
    }

    /deep/ .domainSuffix_box {
        .el-input__inner {
            border-right: none;
            padding-right: 0px;
        }

        .el-input-group__append {
            background-color: #fff;
            border-left: none;
            padding-left: 0px;
        }

        &:hover {
            .el-input-group__append {
                border-color: #c0c4cc;
            }
        }

        & :focus {
            .el-input-group__append {
                border-color: #01a6fb;
            }
        }
    }

    /deep/ .is-error {
        .domainSuffix_box {
            .el-input-group__append {
                border-color: #f56c6c;
            }
        }
    }
}

.theme_dialog_box {
    width: 100%;
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;

    .img_box {
        width: 18%;
        height: 200px;
        margin-right: 16px;
        margin-bottom: 20px;
        border: 1px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        cursor: pointer;
        position: relative;

        .el-image {
            width: 100%;
            height: 100%;
        }

        .iconfont {
            position: absolute;
            bottom: 5px;
            right: 5px;
            font-size: 24px;
            color: #01a6fb;
        }
    }
}</style>
